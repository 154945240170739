<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="(v, i) in colleges"
          :key="i"
          :label="v.name"
          :value="v.id"
        ></el-option>
      </el-select>
      <el-input
        v-model="listQuery.name"
        placeholder="请输入教师名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.phone"
        placeholder="请输入教师联系电话"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_normal"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="冻结" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.college.name }} </template>
      </el-table-column>
      <el-table-column label="教师名称" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.name }} </template>
      </el-table-column>
      <el-table-column label="教师简介" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.synopsis }} </template>
      </el-table-column>
      <el-table-column label="教师邮箱" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.email }} </template>
      </el-table-column>
      <el-table-column label="账号" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.account }} </template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.phone }} </template>
      </el-table-column>
      <el-table-column label="教学课程" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag
            type="primary"
            v-for="(item, index) in scope.row.school_course"
            :key="index"
          >
            {{ item.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="在职状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.status == 1">在职</el-tag>
          <el-tag type="primary" v-else-if="scope.row.status == 2">兼职</el-tag>
          <el-tag type="danger" v-else>离职</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_normal">正常</el-tag>
          <el-tag type="danger" v-else>冻结</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '冻结')"
            v-if="scope.row.is_normal"
            >冻结</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '解冻')"
            v-else
            >解冻</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="initializationPassword(scope.row.id)"
            >初始化密码</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="checkSchedule(scope.row.id)"
            >查看课表</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="教师名称" prop="name">
          <el-input type="text" v-model="form.name" clearable />
        </el-form-item>

        <el-form-item label="请选择院系" prop="college_id">
          <el-select
            v-model="form.college_id"
            style="width: 100%"
            @change="getCourseList"
          >
            <el-option
              v-for="item in colleges"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="教师账号" prop="account">
          <el-input type="text" v-model="form.account" clearable />
        </el-form-item>
        <el-form-item label="教师简介" prop="synopsis">
          <el-input type="text" v-model="form.synopsis" clearable />
        </el-form-item>
        <el-form-item label="教师邮箱" prop="email">
          <el-input type="text" v-model="form.email" clearable />
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input type="number" v-model="form.phone" clearable />
        </el-form-item>
        <el-form-item label="在职状态" prop="status">
          <el-select
            v-model="form.status"
            placeholder="请选在职状态"
            style="width: 150px"
            class="filter-item"
          >
            <el-option label="在职" :value="1" />
            <el-option label="兼职" :value="2" />
            <el-option label="离职" :value="3" />
          </el-select>
        </el-form-item>

        <el-form-item label="教学课程" prop="school_course_ids">
          <div class="form_top">
            <el-tag
              v-for="(tag, key) in tags"
              :key="tag.name"
              closable
              :type="tag.type"
              style="margin-right: 10px; margin-bottom: 10px"
              @close="tagClose(key, tag)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
          <div class="form_bom">
            <el-select v-model="value" placeholder="请选择" @change="change">
              <el-option
                v-for="item in tagSelect"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-button type="primary" @click="addTag" style="margin-left: 10px">
              添加
            </el-button> -->
          </div>
          <!-- <div
            v-for="(item, index) in school_course_idss"
            :key="index"
            style="margin-bottom: 10px"
          >
            <el-row
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <el-col :span="14">
                <el-input type="text" v-model="item.tagsTitle" clearable />
              </el-col>
              <el-col
                :span="10"
                style="
                  padding-left: 10px;
                  display: flex;
                  justify-content: flex-start;
                "
              >
                <i
                  class="el-icon-circle-plus"
                  style="font-size: 30px; cursor: pointer"
                  @click="addTagsTitle"
                  v-if="index == school_course_idss.length - 1"
                ></i>
                <i
                  class="el-icon-remove"
                  style="font-size: 30px; cursor: pointer"
                  @click="delTagsTitle(index)"
                  v-if="school_course_idss.length != 1"
                ></i>
              </el-col>
            </el-row>
          </div> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: [],
      colleges: [],
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        college_id: "",
        is_on: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      btnLoading: false,
      textMap: { update: "修改", create: "新增" },
      form: {
        id: "",
        name: "",
        college_id: "",
        account: "",
        synopsis: "",
        email: "",
        phone: "",
        status: 1,
        school_course_ids: [],
      },
      rules: {
        name: [{ required: true, message: "教师不能为空", trigger: "change" }],
        college_id: [
          { required: true, message: "院系不能为空", trigger: "change" },
        ],
        account: [
          { required: true, message: "教师账号不能为空", trigger: "change" },
        ],
        synopsis: [
          { required: true, message: "教师介绍不能为空", trigger: "change" },
        ],
        email: [
          { required: true, message: "教室邮箱不能为空", trigger: "change" },
        ],
        phone: [
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
        school_course_ids: [
          { required: true, message: "教学课程不能为空", trigger: "change" },
        ],
        // tags: [
        //   {type:"array" ,required: true, message: "教学课程不能为空", trigger: "change" },
        // ],
      },
      college_ids: [],
      school_course_idss: [{ tagsTitle: "" }],
      tagSelect: [],
      Select_name: "",
      Select_id: "",
      tags: [],
      value: "",
    };
  },
  created() {
    // this.getCourseList();
    // this.getCollegeList();
    this.getCollegeList();
    this.getList();
  },
  methods: {
    cancel() {
      this.resetForm();
      this.tags = [];
      console.log(this.form, "==================取消");
      console.log(this.list, "==================取消");
      this.dialogFormVisible = false;
      // this.$forceUpdate()
      this.getList();
    },
    tagClose(key, data) {
      this.tags.splice(key, 1);
      console.log(this.form);
      console.log(this.form.school_course_ids);
      // let index= this.form.school_course_ids.findIndex(item=>item.id==data.id)
      // console.log(index);
      this.form.school_course_ids.splice(key, 1);
      this.getCourseList(false);
    },
    change(e) {
      console.log(e);
      this.value = e;
      let index = this.tagSelect.findIndex((item) => item.id == e);
      this.Select_name = this.tagSelect[index].name;
      this.Select_id = this.tagSelect[index].id;
      this.value = "";
      if (this.Select_id) {
        this.tags.push({ id: this.Select_id, name: this.Select_name });
        this.form.school_course_ids.push(this.Select_id);
        this.getCourseList(false);
      }
    },
    // addTag() {
    //   this.value = "";
    //   if (this.Select_id) {
    //     this.tags.push({ id: this.Select_id, name: this.Select_name });
    //     this.form.school_course_ids.push(this.Select_id);
    //     this.getCourseList();
    //   }
    //   console.log(this.list, "=============addTag");
    //   this.Select_id = "";
    //   this.Select_name = "";
    // },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/teacher/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 999999999999999,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getCourseList(ref) {
      if (ref) {
        this.tags = [];
        console.log(this.form.school_course_ids);
        this.form.school_course_ids = [];
      }
      let ids = "";
      if (this.form.school_course_ids.length > 0) {
        this.form.school_course_ids.forEach((item) => {
          ids = ids + item + ",";
        });
      }
      request({
        url: "/api/schoolend/course/list",
        method: "get",
        params: {
          limit: 99999999999999,
          ids: ids,
          college_id: this.form.college_id,
        },
      }).then((response) => {
        this.tagSelect = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        college_id: "",
        account: "",
        synopsis: "",
        email: "",
        phone: "",
        status: 1,
        school_course_ids: [],
      };
    },
    handleCreate() {
      this.tags = [];
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign(this.form, row);
      // this.form = row;
      this.tags = row.school_course;
      // this.form.school_course_ids=[]
      // for (let index = 0; index < row.school_course.length; index++) {
      //   const id = row.school_course[index].id;
      //   this.form.school_course_ids.push(id)
      // }
      row.school_course.forEach((item) => {
        this.form.school_course_ids.push(item.id);
      });
      console.log(row.school_course, "===============");

      this.getCourseList();
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      // this.form.school_course_ids = [];
      // this.tags.forEach((e) => {
      //   this.form.school_course_ids.push(e.id);
      // });
      console.log(this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/teacher/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/teacher/changeNormal",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //增加标签
    addTagsTitle() {
      let obj = {
        tagsTitle: "",
      };
      this.school_course_idss.push(obj);
    },
    // 删除标签
    delTagsTitle(idx) {
      this.school_course_idss.splice(idx, 1);
    },
    //查看课表
    checkSchedule(v) {
      this.$router.push(`/teacher/schedule?teacher_id=${v}`);
    },
    //初始化密码
    initializationPassword(v, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/teacher/initPassword",
            method: "post",
            data: { id: v },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
